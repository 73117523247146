import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://sedom.com.do">
                sedom.com.do
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function PoliticasPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="lg">
                <p><strong>Última actualización: 2024/06/01</strong></p>
                <p>Sedom App (en adelante, "la Aplicación") se compromete a proteger su privacidad. Esta Política de Privacidad explica cómo recopilamos, usamos, divulgamos y protegemos su información cuando utiliza nuestra aplicación móvil. Al acceder o usar la Aplicación, usted acepta los términos de esta Política de Privacidad.</p>
                <h4>1. Información que Recopilamos</h4>
                <p><strong>1.1 Información Personal</strong> Podemos recopilar información personal identificable que usted nos proporcione voluntariamente al registrarse en la Aplicación, como su nombre, dirección de correo electrónico y cualquier otra información que elija proporcionarnos.</p>
                <p><strong>1.2 Información No Personal</strong> Podemos recopilar información no personal sobre usted cuando interactúa con nuestra Aplicación, como el tipo de dispositivo móvil que utiliza, el identificador único de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza y otra información de diagnóstico.</p>
                <p><strong>1.3 Información de Uso</strong> Recopilamos información sobre cómo accede y utiliza la Aplicación. Esta información puede incluir, entre otras cosas, las páginas u otras funciones de la Aplicación que usted visita, la hora y fecha de su visita, el tiempo que pasa en esas páginas o características, información de eventos del dispositivo, y otros datos de uso y diagnóstico.</p>
                <h4>2. Cómo Utilizamos Su Información</h4>
                <p><strong>2.1 Proveer y Mejorar la Aplicación</strong> Utilizamos la información que recopilamos para operar, mantener y mejorar la Aplicación, incluyendo:</p>
                <ul>
                    <li>Personalizar su experiencia en la Aplicación.</li>
                    <li>Responder a sus comentarios y preguntas.</li>
                    <li>Proporcionarle soporte al cliente.</li>
                </ul>
                <p><strong>2.2 Comunicaciones</strong> Podemos utilizar su información personal para comunicarnos con usted sobre actualizaciones, mejoras, ofertas especiales y otros servicios que puedan interesarle. Si no desea recibir estas comunicaciones, puede optar por no recibirlas siguiendo las instrucciones de exclusión que se proporcionan en el mensaje.</p>
                <h4>3. Cómo Compartimos Su Información</h4>
                <p><strong>3.1 Proveedores de Servicios</strong> Podemos compartir su información con terceros que realizan servicios en nuestro nombre, como el alojamiento de datos, el análisis de datos, el procesamiento de pagos, la prestación de servicios al cliente y la realización de actividades promocionales.</p>
                <p><strong>3.2 Requisitos Legales</strong> Podemos divulgar su información si creemos de buena fe que dicha divulgación es necesaria para:</p>
                <ul>
                    <li>Cumplir con una obligación legal.</li>
                    <li>Proteger y defender nuestros derechos o propiedad.</li>
                    <li>Prevenir o investigar posibles irregularidades en conexión con la Aplicación.</li>
                    <li>Proteger la seguridad personal de los usuarios de la Aplicación o del público.</li>
                    <li>Protegerse contra la responsabilidad legal.</li>
                </ul>
                <h4>4. Seguridad de Su Información</h4>
                <p>Nos comprometemos a proteger su información. Implementamos medidas de seguridad razonables para proteger su información contra acceso no autorizado, alteración, divulgación o destrucción. Sin embargo, recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro.</p>
                <h4>5. Retención de Datos</h4>
                <p>Conservaremos su información personal solo durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad, a menos que una ley aplicable requiera o permita un período de retención más prolongado.</p>
                <h4>6. Sus Derechos de Privacidad</h4>
                <p>Usted tiene derecho a acceder, corregir, actualizar o solicitar la eliminación de su información personal. Para ejercer estos derechos, por favor, póngase en contacto con nosotros a través de la información de contacto proporcionada a continuación.</p>
                <h4>7. Cambios a Esta Política de Privacidad</h4>
                <p>Podemos actualizar esta Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. Se le aconseja revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.</p>
                <h4>8. Contacto</h4>
                <p>Si tiene alguna pregunta sobre esta Política de Privacidad, por favor, póngase en contacto con nosotros a través de:</p>
                <p>Correo electrónico: inflo@sedom.com.do</p>
            </Container>
            <Box
                component="footer" textAlign="center"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="lg">
                    <Copyright />
                </Container>
            </Box>
        </Box>
    );
}