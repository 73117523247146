import {
    createBrowserRouter
} from "react-router-dom";
import PoliticasPage from "./pages/PoliticasPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <div>Hello world!</div>,
    },
    {
        path: "/politicas",
        element: <PoliticasPage />,
    }

]);